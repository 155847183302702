import { SidebarWithIcons } from '@flock/shared-ui'
import { HOMEPAGE_PATH } from '@flock/shared-ui/src/routeConstants'
import { Box, Grid, Typography } from '@mui/material'
import { navigate } from 'gatsby'
import React from 'react'
import FloatingMessage from '../../OfferPageComponents/FloatingMessage'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { OfferPageSidebarProps } from './offerPageSidebarTypes'

const OfferPageSidebarDesktop = (props: OfferPageSidebarProps) => {
  const {
    sidebarRef,
    sidebarHeight,
    leadStatus,
    sidebarItems,
    onChangeSidebar,
    selectedSection,
  } = props

  return (
    <Box
      position="sticky"
      top="40px"
      width="100%"
      sx={{ pointerEvents: 'none' }}
    >
      <Box ref={sidebarRef} marginBottom={`${sidebarHeight * -1}px`}>
        <SectionLayout name="sidebar" sidePadding="0px" columns={12}>
          <Grid item xs={3} sx={{ pointerEvents: 'all' }}>
            <Box
              position="absolute"
              onClick={() => navigate(HOMEPAGE_PATH)}
              top="28px"
              padding="16px"
            >
              <Typography
                variant="h4"
                fontWeight={500}
                color="moneyGreen.main"
                sx={{ '&:hover': { cursor: 'pointer' } }}
              >
                F L O C K
              </Typography>
            </Box>

            <SidebarWithIcons
              items={sidebarItems}
              onChange={onChangeSidebar}
              selected={selectedSection}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3} sx={{ pointerEvents: 'all' }}>
            <Box pt="8px">
              <FloatingMessage leadStatus={leadStatus} />
            </Box>
          </Grid>
        </SectionLayout>
      </Box>
    </Box>
  )
}

export default OfferPageSidebarDesktop
