import {
  ArchFrame,
  QuotationLargeIcon,
  TrackedButton,
  TrackedLink,
} from '@flock/shared-ui'
import {
  Box,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import Vimeo from 'react-vimeo-embed'

import { STORIES_PATH } from '@flock/shared-ui/src/routeConstants'
import PrelimOfferSection from './PrelimOfferSection'
import WayneAndVickie from '../../../images/stories/wayne-and-vickie-l.webp'
import Shirlee from '../../../images/stories/shirlee-t.webp'
import Josh from '../../../images/stories/josh-b.webp'

type StoryCardProps = {
  name: string
  src: string
  about: string
  problems: string[]
  solutions: string[]
  quote: string
  joinYear: number
  onVideoOpen?: () => void
}

const StoryCard = (props: StoryCardProps) => {
  const {
    name,
    src,
    about,
    problems,
    solutions,
    quote,
    joinYear,
    onVideoOpen,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  let columns = 12
  if (isMobile) {
    columns = 4
  } else if (isTablet) {
    columns = 6
  }

  return (
    <Grid item sm={6} md={12}>
      <Fade duration={400} distance="48px" bottom>
        <Grid container spacing={4} columns={columns}>
          <Grid
            item
            sm={3}
            md={4}
            display="flex"
            flexDirection="column"
            gap="32px"
            alignItems="center"
          >
            <ArchFrame src={src} size={isTablet ? 'mediumSmall' : 'medium'}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={{
                  sm: '0px',
                  md: '2px',
                }}
                paddingBottom="10px"
              >
                <Typography variant={isTablet ? 'h3' : 'h2m'}>
                  {name}
                </Typography>
                <Typography variant="c1">JOINED {joinYear}</Typography>
              </Box>
            </ArchFrame>
            {!isTablet && (
              <Box
                display="flex"
                justifyContent="center"
                padding="16px"
                gap="8px"
                sx={{
                  background: `rgba(255, 255, 255, 0.32)`,
                  borderRadius: '24px',
                }}
              >
                <TrackedLink
                  onClick={
                    onVideoOpen || (() => window.open(STORIES_PATH, '_blank'))
                  }
                  variant="p1"
                  textAlign="center"
                  sx={{ fontWeight: 300 }}
                >
                  {onVideoOpen
                    ? `Watch Shirlee's testimonial video with Ari, founder and CEO of Flock.`
                    : `Read more stories from retiring landlords like ${name}`}
                </TrackedLink>
              </Box>
            )}
          </Grid>

          {!isTablet ? (
            <Grid item md={8}>
              <Grid container columns={8} spacing={4}>
                <Grid
                  item
                  xs={4}
                  display="flex"
                  flexDirection="column"
                  gap="16px"
                >
                  <Typography variant="h4" color="moneyGreen.main">
                    About
                  </Typography>
                  <Typography variant="p2">{about}</Typography>
                </Grid>
                <Grid item xs={4} display="flex" alignItems="center">
                  <Box>
                    <Box position="absolute" sx={{ mt: '-16px', ml: '-16px' }}>
                      <QuotationLargeIcon width="49px" height="33px" />
                    </Box>
                    <Typography
                      variant="h3"
                      color="moneyGreen.main"
                      sx={{
                        fontSize: '20px',
                        lineHeight: '24px',
                        fontWeight: 800,
                      }}
                    >
                      {quote}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="flex"
                  flexDirection="column"
                  gap="16px"
                >
                  <Typography variant="h4" color="moneyGreen.main">
                    Problems
                  </Typography>
                  <Box component="ul" sx={{ margin: 0 }}>
                    {problems.map((problemString) => (
                      <li key={problemString}>
                        <Typography variant="p2" color="moneyGreen.main">
                          {problemString}
                        </Typography>
                      </li>
                    ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="flex"
                  flexDirection="column"
                  gap="16px"
                >
                  <Typography variant="h4" color="moneyGreen.main">
                    Solutions
                  </Typography>
                  <Box component="ul" sx={{ margin: 0 }}>
                    {solutions.map((solutionString) => (
                      <li key={solutionString}>
                        <Typography variant="p2" color="moneyGreen.main">
                          {solutionString}
                        </Typography>
                      </li>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                item
                sm={3}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="8px"
              >
                <Typography variant="h4" color="moneyGreen.main">
                  About
                </Typography>
                <Typography variant="p2">{about}</Typography>
              </Grid>
              <Grid item sm={6}>
                <Box>
                  <Box position="absolute" sx={{ mt: '-16px', ml: '-16px' }}>
                    <QuotationLargeIcon width="49px" height="33px" />
                  </Box>
                  <Typography
                    variant="h3"
                    color="moneyGreen.main"
                    sx={{
                      fontSize: '20px',
                      lineHeight: '24px',
                      fontWeight: 800,
                    }}
                  >
                    {quote}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} display="flex" flexDirection="column" gap="8px">
                <Typography variant="h4" color="moneyGreen.main">
                  Problems
                </Typography>
                <Box component="ul" sx={{ margin: 0 }}>
                  {problems.map((problemString) => (
                    <li key={problemString}>
                      <Typography variant="p2" color="moneyGreen.main">
                        {problemString}
                      </Typography>
                    </li>
                  ))}
                </Box>
              </Grid>
              <Grid item sm={3} display="flex" flexDirection="column" gap="8px">
                <Typography variant="h4" color="moneyGreen.main">
                  Solutions
                </Typography>
                <Box component="ul" sx={{ margin: 0 }}>
                  {solutions.map((solutionString) => (
                    <li key={solutionString}>
                      <Typography variant="p2" color="moneyGreen.main">
                        {solutionString}
                      </Typography>
                    </li>
                  ))}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Fade>
    </Grid>
  )
}

StoryCard.defaultProps = {
  onVideoOpen: undefined,
}

type PrelimStoriesSectionProps = {
  isGeneric?: boolean
}

const PrelimStoriesSection = (props: PrelimStoriesSectionProps) => {
  const { isGeneric } = props
  const [selectedSection, setSelectedSection] = useState(0)
  const [videoOpen, setVideoOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <PrelimOfferSection
      sectionId="stories"
      nextId={isGeneric ? 'important-resources' : 'next-steps'}
      nextCta={isGeneric ? 'Find out more.' : "What's next?"}
    >
      <Grid item xs={12}>
        <Typography variant="h2" alignSelf="flex-start" color="moneyGreen.main">
          We&apos;ve empowered hundreds of clients like you to live life to the
          fullest.
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        display="flex"
        flexDirection={{
          xs: 'column',
          sm: 'row',
          md: 'row',
        }}
        justifyContent={{
          sm: 'center',
          md: 'flex-end',
        }}
        alignItems="center"
        gap="16px"
        sx={{ paddingTop: '40px!important' }}
      >
        <Typography variant="p2">
          {isMobile ? 'My main problem is: ' : 'I want to:'}
        </Typography>
        <Box display="flex" gap="16px">
          <TrackedButton
            variant="secondary"
            size={isMobile ? 'micro' : 'mini'}
            onClick={() => setSelectedSection(0)}
            sx={{
              backgroundColor:
                selectedSection === 0 ? 'green1.main' : undefined,
            }}
          >
            {isMobile ? 'taxes' : 'defer taxes'}
          </TrackedButton>
          <TrackedButton
            variant="secondary"
            size={isMobile ? 'micro' : 'mini'}
            onClick={() => setSelectedSection(1)}
            sx={{
              backgroundColor:
                selectedSection === 1 ? 'green1.main' : undefined,
            }}
          >
            {isMobile ? 'time' : 'save time'}
          </TrackedButton>
          <TrackedButton
            variant="secondary"
            size={isMobile ? 'micro' : 'mini'}
            onClick={() => setSelectedSection(2)}
            sx={{
              backgroundColor:
                selectedSection === 2 ? 'green1.main' : undefined,
            }}
          >
            {isMobile ? 'optimization' : 'explore options'}
          </TrackedButton>
        </Box>
      </Grid>

      {selectedSection === 0 && (
        <StoryCard
          name="Wayne & Vickie L."
          src={WayneAndVickie}
          about={`Meet the newly retired Wayne and Vickie. They started purchasing investment properties in the early 2000s to fund their children's college expenses. But after the Great Financial Crisis reduced market values, they ended up keeping their rentals for retirement income. They wanted to be "done with toilets" and find a way to exit their landlord responsibilities.`}
          quote="We were the perfect fit for Flock. We have been landlords and business people for a long time. We were tired... and wanted to retire. We wanted to hand over our properties and still hold value."
          problems={[
            'Wayne and Vickie wanted to avoid the capital gains taxes they would face in the event of a sale.',
            'Self-managing their rentals across two states was inconvenient—Vickie grew frustrated with nonperforming tenants, and Wayne struggled with maintenance burdens.',
            'They wanted to maintain exposure to real estate but found REITs too restrictive.',
          ]}
          solutions={[
            `Wayne and Vickie fully deferred their tax liability through Flock's 721 Exchange.`,
            `Flock took over all property management duties—a weight lifted for Wayne and Vickie.`,
            `They appreciated Flock's operational expertise and the consistent cash flow from this investment.`,
          ]}
          joinYear={2021}
        />
      )}
      {selectedSection === 1 && (
        <StoryCard
          name="Shirlee T."
          src={Shirlee}
          about="Shirlee is a single mom and a full-time physician. She purchased her first home in Denver in 2003 but soon thereafter wanted to move to be closer to her son's school. At that time, she decided to keep the property as a rental, hoping the investment would provide for her son's future."
          quote="You get to keep all the benefits of financial growth, yet not have the headache... [Flock] has solved so many problems for me, and I’m so happy."
          problems={[
            'Shirlee had trouble managing her rental from afar and lacked cash on hand to invest in necessary renovations.',
            `Repairs were expensive, but so was Shirlee's time—countless hours spent on leasing and coordinating contractors.`,
            'These typical landlord stressors took their toll on Shirlee, and she needed a way out.',
          ]}
          solutions={[
            `No longer responsible for leasing, repairs, or management, Shirlee handed off all responsibilities to Flock's team.`,
            `Once Shirlee contributed her home, Flock was able to make necessary renovations at lower costs, ultimately benefitting her investment.`,
            `Shirlee was able to leave the headaches behind and become a truly passive real estate investor.`,
          ]}
          joinYear={2021}
          onVideoOpen={() => setVideoOpen(true)}
        />
      )}
      {selectedSection === 2 && (
        <StoryCard
          name="Josh B."
          src={Josh}
          about="Josh is a consultant who lives in Colorado with his family. He purchased his first home in Denver at the beginning of a significant real estate boom and eventually expanded his portfolio to multiple properties across four states. While he thought this was a valuable experience, he was interested in potential exit opportunities."
          quote="I was convinced that going through Flock’s process was going to be much better than proceeding with the traditional market. And I have to be honest, ever since turning the property over to Flock, everything has gone the exact way it was suggested to go."
          problems={[
            'As Josh’s portfolio grew, the time and effort required to run the properties also increased.',
            'He didn’t believe property managers were profitable, and they had no motivation to prioritize caring for his units.',
            'After selling four properties, he felt that the traditional market was antiquated and exploitative.',
          ]}
          solutions={[
            'Flock provided a competitive offer and transacted quickly and easily.',
            `Flock's team dedicated hours on the phone with Josh during his due diligence, allowing him to get comfortable with the company and the exchange.`,
            'Josh kept the cash flows and appreciation he was used to and left the stress of property management behind.',
          ]}
          joinYear={2021}
        />
      )}
      <Modal
        disableRestoreFocus
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
        disableScrollLock
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 0,
            maxWidth: '100%',
          }}
        >
          <Vimeo
            video="708789562"
            width={isMobile ? '360' : '960'}
            height={isMobile ? '220' : '585'}
            autoplay
          />
        </Box>
      </Modal>
    </PrelimOfferSection>
  )
}

export default PrelimStoriesSection

PrelimStoriesSection.defaultProps = {
  isGeneric: false,
}
