import { SidebarWithIcons } from '@flock/shared-ui'
import { HOMEPAGE_PATH } from '@flock/shared-ui/src/routeConstants'
import { Box, Grid, Typography } from '@mui/material'
import { navigate } from 'gatsby'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { OfferPageSidebarProps } from './offerPageSidebarTypes'

const OfferPageSidebarTablet = (props: OfferPageSidebarProps) => {
  const {
    sidebarRef,
    sidebarHeight,
    sidebarItems,
    onChangeSidebar,
    selectedSection,
  } = props

  return (
    <>
      <Box width="100%">
        <SectionLayout name="sidebar" verticalPadding="0px" columns={7}>
          <Grid item>
            <Box onClick={() => navigate(HOMEPAGE_PATH)}>
              <Typography variant="h4" fontWeight={500} color="moneyGreen.main">
                F L O C K
              </Typography>
            </Box>
          </Grid>
        </SectionLayout>
      </Box>

      <Box
        position="sticky"
        top="40px"
        width="100%"
        sx={{ pointerEvents: 'none' }}
      >
        <Box ref={sidebarRef} marginBottom={`${sidebarHeight * -1}px`}>
          <SectionLayout
            name="sidebar"
            sidePadding="0px"
            verticalPadding="28px"
            columns={7}
          >
            <Grid item xs={1} sx={{ pointerEvents: 'all' }}>
              <SidebarWithIcons
                items={sidebarItems}
                onChange={onChangeSidebar}
                selected={selectedSection}
              />
            </Grid>
          </SectionLayout>
        </Box>
      </Box>
    </>
  )
}

export default OfferPageSidebarTablet
